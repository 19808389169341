<template>
  <div :class="`bg-white ${isWide ? 'row w-100 h-100' : 'h-100'}`">
    <div :class="`${isWide ? 'col-sm d-flex flex-column justify-content-center' : 'mt-10 mx-2'}`">
      <img :class="`${isMobile ? 'm-5' : 'm-10'} pointer`" :src="logoDark" :width="isMobile ? 150 : 200" :height="isMobile ? 35 : 45" @click="$router.push(`/`)" />
      <div :class="`my-auto ${isWide ? 'w-75 mx-auto' : 'w-100'} p-5`">
        <span class="font-25 font-bold">{{ $t($route.name) }}</span>
        <VerifyEmailPhoneComponent></VerifyEmailPhoneComponent>
      </div>
      <TermsComponent></TermsComponent>
    </div>
    <div class="col-sm right-div" v-if="isWide" />
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import VerifyEmailPhoneComponent from "@/view/pages/auth/VerifyEmailPhoneComponent.vue";
import TermsComponent from "@/view/pages/auth/TermsComponent.vue";

import logoDark from '@/assets/logo/logo_dark.png';

export default {
  name: 'VerifyEmailPhone',
  components: {
    VerifyEmailPhoneComponent,
    TermsComponent
  },
  computed: {
    isWide() {
      return this.$store.state.isWide;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      logoDark
    }
  }
};
</script>